import StylableHorizontalMenu_WrapColumnComponent from '../components/StylableHorizontalMenu/viewer/skinComps/WrapColumn/WrapColumn.skin';


const StylableHorizontalMenu_WrapColumn = {
  component: StylableHorizontalMenu_WrapColumnComponent
};


export const components = {
  ['StylableHorizontalMenu_WrapColumn']: StylableHorizontalMenu_WrapColumn
};


// temporary export
export const version = "1.0.0"
